/*
  example:
    import { editWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Put';

    const { result, message, successful } = await editWorkOrderRequest ({
      json: {}
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function editWorkOrderRequest({ kms: kms_ = kms, json } = {}) {
  const message = 'The work order requests could not be edited.'

  console.debug('editWorkOrderRequest=' + JSON.stringify(json))

  try {
    const result = await kms_.put(`/WorkOrderRequests/WorkOrderRequest`, json)

    return {
      successful: true,
      result,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    result: null,
    successful: false,
    message
  }
}

///////
